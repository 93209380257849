@import url(https://fonts.googleapis.com/css?family=Raleway:700,500,600|Open+Sans:400,600,700);
@media print {
  .search__result_wrapper {
    display: none;
  }
}
.search-location-checkbox {
  padding-top: 0.2375rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.multi-select-dropdown {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  position: relative;
}
.multi-select-dropdown .glyphicon-ok {
  width: 8%;
  float: right;
  font-size: 1rem;
}

.alert__message {
  margin-bottom: 0.9375rem;
}
.alert__message--error {
  background-color: #fcdedf;
  color: #ec2227;
  padding: 0.9375rem;
}
.alert__message--error .close {
  background: none;
  border: none;
  color: #ec2227;
  font-size: 30px;
  position: relative;
  top: -17px;
  float: right;
}
.alert__message--review-error {
  background-color: #fcdedf;
  color: #ec2227;
  padding: 0.9375rem;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}
.alert__message--review-error .close {
  background: none;
  border: none;
  color: #ec2227;
  font-size: 30px;
  position: relative;
  top: -17px;
  float: right;
}
.alert__message--info {
  background-color: #c7e5f6;
  color: #087dc2;
  padding: 0.9375rem;
}
.alert__message--info .close {
  background: none;
  border: none;
  color: #087dc2;
  font-size: 30px;
  position: relative;
  top: -17px;
  float: right;
}
.alert__message--success {
  background-color: #dff0d8;
  color: #3c763d;
  padding: 0.9375rem;
}
.alert__message--success .close {
  background: none;
  border: none;
  color: #3c763d;
  font-size: 30px;
  position: relative;
  top: -17px;
  float: right;
}
.alert__message--warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
  padding: 0.9375rem;
}
.alert__message--warning .close {
  background: none;
  border: none;
  color: #8a6d3b;
  font-size: 30px;
  position: relative;
  top: -17px;
  float: right;
}
.alert__message--text {
  text-align: center;
  font-size: 1rem;
}

.alert__message-container {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.modal-open {
  overflow: hidden;
  padding-right: 15px !important;
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  padding: 12px 10px 2px 20px;
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.428571429px;
  color: #087dc2;
  font-size: 0.875rem;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.428571429;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer:before, .modal-footer:after {
  content: " ";
  display: table;
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

/*@media (min-width: $sm-screen-size) {

  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: $modal-md-size;
    margin: 30px auto;
  }
  .modal-content {
    @include box-shadow(0 5px 15px rgba(0,0,0,.5));
  }

  // Modal sizes
  .modal-sm {
    width: $modal-sm-size;
  }
}
@media (min-width: $md-screen-size) {
  .modal-lg {
    width: $modal-lg-size;
  }
}*/
.modal-wrapper .modal-dialog {
  margin: 30px auto;
  max-width: 900px;
}
@media (min-width: 1280px) {
  .modal-wrapper .modal-dialog {
    max-width: 1000px;
  }
}
@media (min-width: 1600px) {
  .modal-wrapper .modal-dialog {
    max-width: 1200px;
  }
}

.modal-content {
  padding: 0.9375rem;
}

.modal__content {
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.modal__content_loader {
  padding: 15px;
  margin-left: 45%;
}
.modal__header {
  color: #087dc2;
  font-size: 0.875rem;
  width: 100%;
}
.modal__header:before, .modal__header:after {
  content: " ";
  display: table;
}
.modal__header:after {
  clear: both;
}
.modal__header_heading {
  width: 95%;
  float: left;
}
.modal__header_close {
  width: 5%;
  float: right;
  text-align: right;
  cursor: pointer;
}
.modal__header_info {
  margin-top: 7px;
  font-size: 1.125rem;
  background-color: #eaf5fc;
  height: 35px;
  padding: 5px 5px 5px 7px;
}

.heading__main:before, .heading__main:after {
  content: " ";
  display: table;
}
.heading__main:after {
  clear: both;
}

.header__info_part {
  color: #087dc2;
  float: left;
}
.header__info_part1 {
  color: #f48083;
  margin-left: 5px;
  font-family: "Open Sans";
}
.header__info_part2 {
  color: #087dc2;
  float: right;
  font-size: 0.875rem;
  margin: 2px;
}
.header__info_subpart1 {
  padding-right: 10px;
}
.header__info_subpart2 {
  padding: 3px 20px;
  background-color: white;
}

.highlights__sidebar {
  float: left;
  width: 25%;
  height: 100%;
  background-color: #EEEFF3;
}
.highlights__hotel-details {
  float: right;
  width: 75%;
  height: 100%;
  margin-top: 10px;
}

.sidebar__back-button {
  margin: 20px;
  background-color: white;
  border: 5px solid #087dc2;
  padding: 25px;
}
.sidebar__back-button button {
  cursor: pointer;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border: none;
  line-height: inherit;
  border-radius: 0;
  background-image: none;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  background-color: #ec2227;
  color: #fff;
  display: block;
}
.sidebar__back-button button:hover {
  background-color: #9b0d11;
}
.sidebar__tabs {
  width: 100%;
  height: 80%;
  padding-bottom: 10px;
}
.sidebar__tabs > li {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  position: relative;
}
.sidebar__tabs > li > a {
  color: #087dc2;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
.sidebar__tabs > li:hover {
  background-color: #087dc2;
}
.sidebar__tabs > li:hover a {
  color: #fff;
}
.sidebar__tabs > li:after {
  content: "";
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: white;
  position: absolute;
  top: 100%;
}
.sidebar__tabs .active {
  background-color: #fff;
}
.sidebar__tabs .active a {
  color: #ec2227;
}

.modal__header {
  color: #087dc2;
  font-size: 0.875rem;
}
.modal__header_heading {
  padding-left: 7px;
  float: left;
}
.modal__header_close {
  float: right;
  cursor: pointer;
}
.modal__header_info {
  margin-top: 7px;
  font-size: 1.125rem;
  background-color: #eaf5fc;
  height: 35px;
  padding: 5px 5px 5px 7px;
}
.modal__header .alert__message--error {
  margin-top: 10px;
}

.heading__main:before, .heading__main:after {
  content: " ";
  display: table;
}
.heading__main:after {
  clear: both;
}

.header__info {
  color: #087dc2;
}

.header__info_part1 {
  color: #f48083;
  float: left;
  font-size: 0.875rem;
  margin: 2px;
}

.header__info_part2 {
  color: #087dc2;
  float: right;
  font-size: 0.875rem;
  margin: 2px;
  margin-top: 0rem;
  font-family: "Open Sans";
}

.header__info_part3 {
  color: #f48083;
  font-size: 0.875rem;
  margin: 2px;
}

.adhoc-notification-header {
  margin-top: 0rem;
  padding: 0.25rem;
  color: #1d771d;
  background-color: #F5D76E;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.adhoc-notification-header:before, .adhoc-notification-header:after {
  content: " ";
  display: table;
}
.adhoc-notification-header:after {
  clear: both;
}
.adhoc-notification-header .adhoc-message {
  font-size: 0.9rem;
  padding-left: 0.25rem;
}

@media print {
  .modal-content {
    box-shadow: none;
    border: none;
  }
}
.add-more-products {
  width: 50%;
}
.add-more-products__container {
  float: left;
}
.add-more-products__container .dropdown-menu {
  min-width: 100%;
  cursor: pointer;
}
.add-more-products__container .dropdown-menu li:focus, .add-more-products__container .dropdown-menu li:hover {
  color: #838383;
  background-color: #f0f5f8;
}
.add-more-products__container .dropdown-menu li {
  display: block;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.42857143;
  color: #838383;
}
.add-more-products__container .open .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #065888;
}
.add-more-products button {
  padding: 6px 12px;
  color: #fff;
  background-color: #087dc2;
}
.add-more-products button:focus, .add-more-products button:hover {
  background-color: #065888;
}
.add-more-products button:disabled {
  cursor: not-allowed;
}
.add-more-products__content {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  background-color: #fff;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  width: 100%;
}
.add-more-products__content:before, .add-more-products__content:after {
  content: " ";
  display: table;
}
.add-more-products__content:after {
  clear: both;
}
.add-more-products__button {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  background-color: #087dc2;
  width: 13%;
  color: #fff;
  font-weight: 700;
  margin-left: 0.63rem;
  float: left;
  text-align: center;
  margin-top: 0.27rem;
  margin-bottom: 0.27rem;
}

.checkbox-component {
  font-size: 1.25rem;
  cursor: pointer;
}
.checkbox-component input[type=checkbox] {
  display: none;
}
.checkbox-component label {
  margin-bottom: 0;
}
.checkbox-component__disabled {
  cursor: not-allowed;
}

.multi-select .multiselect-parent {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.multi-select .open > .dropdown-menu {
  display: block;
}
.multi-select .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  list-style: none;
  width: 100%;
  padding: 5px 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  max-height: 250px;
  border: 1px solid #eceeef;
}
.multi-select .dropdown-menu .divider {
  height: 0;
  margin: 0;
}
.multi-select ul {
  margin: 0;
}
.multi-select .caret {
  left: 95%;
  top: 50%;
  position: absolute;
}
.multi-select .dropdown-header {
  font-size: 12px;
  color: #55595c;
}
.multi-select .dropdown-header, .multi-select .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px 7px 20px;
  line-height: 1.42857;
  white-space: nowrap;
  font-weight: 400;
  color: inherit;
}
.multi-select .dropdown-header:disabled, .multi-select .dropdown-menu > li > a:disabled {
  cursor: not-allowed;
}
.multi-select .multi-select-field {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.multi-select .dropdown-menu > li > a {
  outline: none;
  cursor: pointer;
}

.airline-selector__wrapper {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 50%;
  float: left;
}
.airline-selector__wrapper .multi-select {
  margin-top: 0.25rem;
}
.airline-selector__wrapper .multi-select .dynamic-multi-select__label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.airline-selector__wrapper .multi-select .glyphicon-ok {
  width: 8%;
  float: right;
  font-size: 1rem;
}
.airline-selector__wrapper .multi-select .multi-airline-select {
  width: 92%;
}
.airline-selector__wrapper .multi-select .multi-airline-select:before, .airline-selector__wrapper .multi-select .multi-airline-select:after {
  content: " ";
  display: table;
}
.airline-selector__wrapper .multi-select .multi-airline-select:after {
  clear: both;
}
.airline-selector__wrapper .multi-select .multi-airline-select__name {
  float: left;
  width: 88%;
}
.airline-selector__wrapper .multi-select .multi-airline-select__image {
  float: left;
  width: 12%;
  margin-top: -7px;
}
.airline-selector__wrapper .multi-select .multi-airline-select__image img {
  border-radius: 50%;
  border: 1px solid;
  width: 80%;
}

.persisted-dynamic-form__content:before, .persisted-dynamic-form__content:after {
  content: " ";
  display: table;
}
.persisted-dynamic-form__content:after {
  clear: both;
}
.persisted-dynamic-form__link {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  cursor: pointer;
  text-align: left;
  color: #087dc2;
}
.persisted-dynamic-form__dynamic-form {
  width: 100%;
}
.persisted-dynamic-form__dynamic-form:before, .persisted-dynamic-form__dynamic-form:after {
  content: " ";
  display: table;
}
.persisted-dynamic-form__dynamic-form:after {
  clear: both;
}
.persisted-dynamic-form__header {
  width: 100%;
  background-color: #f0f5f8;
}
.persisted-dynamic-form__header:before, .persisted-dynamic-form__header:after {
  content: " ";
  display: table;
}
.persisted-dynamic-form__header:after {
  clear: both;
}
.persisted-dynamic-form__header_title {
  line-height: 1.428571429;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-align: center;
  color: #087dc2;
  font-size: 20px;
  margin: 0;
  width: 95%;
  float: left;
}
.persisted-dynamic-form__header_close-icon {
  cursor: pointer;
  float: right;
}
.persisted-dynamic-form__heading-container {
  display: inline-table;
  width: 100%;
  border-bottom: 1px solid #c7e5f6;
}
.persisted-dynamic-form__heading-container:before, .persisted-dynamic-form__heading-container:after {
  content: " ";
  display: table;
}
.persisted-dynamic-form__heading-container:after {
  clear: both;
}
.persisted-dynamic-form__heading-container_heading, .persisted-dynamic-form__heading-container_close-button {
  font-size: 1.25rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.persisted-dynamic-form__heading-container_heading {
  width: 90%;
  color: #087dc2;
  text-align: center;
  float: left;
}
.persisted-dynamic-form__heading-container_close-button {
  text-align: right;
  cursor: pointer;
  float: right;
}
.persisted-dynamic-form__summary {
  padding: 0.725rem;
  border: 10px solid #c7e5f6;
  margin-bottom: 0.725rem;
  font-family: "Open Sans";
}
.persisted-dynamic-form__content-disable {
  cursor: not-allowed;
}

.dynamic-form__save-button {
  display: table;
  content: "";
  width: 100%;
  padding: 1rem 1rem 1rem 4rem;
}
.dynamic-form__save-button_submit {
  float: right;
  font-size: 1.25rem;
  color: #fff;
  background-color: #087dc2;
  text-transform: capitalize;
  width: 33%;
  border: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.dynamic-form .dynamic-number-field, .dynamic-form .dynamic-text-field, .dynamic-form .dynamic-text-area-field, .dynamic-form .dynamic-single-select, .dynamic-form .dynamic-multi-select, .dynamic-form .dynamic-date-field, .dynamic-form .date-field, .dynamic-form .dynamic-date-range-field, .dynamic-form .dynamic-time-field, .dynamic-form .dynamic-html-field, .dynamic-form .dynamic-selection-grouped {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  min-height: 90px;
}
.dynamic-form .dynamic-number-field__value, .dynamic-form .dynamic-text-field__value, .dynamic-form .dynamic-text-area-field__value, .dynamic-form .dynamic-single-select__value, .dynamic-form .dynamic-multi-select__value, .dynamic-form .dynamic-date-field__value, .dynamic-form .date-field__value, .dynamic-form .dynamic-date-range-field__value, .dynamic-form .dynamic-time-field__value, .dynamic-form .dynamic-html-field__value, .dynamic-form .dynamic-selection-grouped__value {
  padding-right: 2.5rem;
}
.dynamic-form .dynamic-number-field__value_text-area, .dynamic-form .dynamic-text-field__value_text-area, .dynamic-form .dynamic-text-area-field__value_text-area, .dynamic-form .dynamic-single-select__value_text-area, .dynamic-form .dynamic-multi-select__value_text-area, .dynamic-form .dynamic-date-field__value_text-area, .dynamic-form .date-field__value_text-area, .dynamic-form .dynamic-date-range-field__value_text-area, .dynamic-form .dynamic-time-field__value_text-area, .dynamic-form .dynamic-html-field__value_text-area, .dynamic-form .dynamic-selection-grouped__value_text-area {
  width: 100%;
}
.dynamic-form .dynamic-number-field__label, .dynamic-form .dynamic-text-field__label, .dynamic-form .dynamic-text-area-field__label, .dynamic-form .dynamic-single-select__label, .dynamic-form .dynamic-multi-select__label, .dynamic-form .dynamic-date-field__label, .dynamic-form .date-field__label, .dynamic-form .dynamic-date-range-field__label, .dynamic-form .dynamic-time-field__label, .dynamic-form .dynamic-html-field__label, .dynamic-form .dynamic-selection-grouped__label {
  margin-bottom: 0;
  color: #087dc2;
  font-size: 0.875rem;
}
.dynamic-form .dynamic-number-field select, .dynamic-form .dynamic-text-field select, .dynamic-form .dynamic-text-area-field select, .dynamic-form .dynamic-single-select select, .dynamic-form .dynamic-multi-select select, .dynamic-form .dynamic-date-field select, .dynamic-form .date-field select, .dynamic-form .dynamic-date-range-field select, .dynamic-form .dynamic-time-field select, .dynamic-form .dynamic-html-field select, .dynamic-form .dynamic-selection-grouped select {
  display: block;
  width: 100%;
  height: 2.152rem;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
}
.dynamic-form .form-field-validation-error {
  width: 100%;
  color: #ec2227;
  margin-bottom: 0px;
}
.dynamic-form .dynamic-number-field-content, .dynamic-form .dynamic-text-field-content, .dynamic-form .dynamic-text-area-field-content, .dynamic-form .dynamic-single-select-content, .dynamic-form .dynamic-multi-select-content, .dynamic-form .dynamic-date-field-content, .dynamic-form .dynamic-time-field-content, .dynamic-form .dynamic-html-field, .dynamic-form .dynamic-selection-grouped {
  width: 100%;
  display: table;
  content: "";
}
.dynamic-form input[type=text], .dynamic-form input[type=number] {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 2.125rem;
}
.dynamic-form input[type=number]::-webkit-inner-spin-button,
.dynamic-form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.dynamic-form input[type=number] {
  -moz-appearance: textfield;
}
.dynamic-form .dynamic-grouped-form {
  width: 100%;
  display: table;
  content: "";
  border: 2px solid #c7e5f6;
  padding: 0.5rem;
  min-height: 6.313rem;
}
.dynamic-form .group-form {
  width: 100%;
  display: table;
  content: "";
}
.dynamic-form .group-form__title {
  background-color: #c7e5f6;
  color: #087dc2;
  font-size: 1.25rem;
  padding-left: 0.5rem;
  width: 100%;
  font-weight: 500;
}
.dynamic-form .dynamic-form-actions {
  display: table;
  content: "";
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}
.dynamic-form .dynamic-form-actions__add {
  width: 100%;
  float: left;
}
.dynamic-form .dynamic-form-actions__add_plus {
  background: transparent;
}
.dynamic-form .dynamic-form-actions__add_plus:hover, .dynamic-form .dynamic-form-actions__add_plus:focus {
  color: #087dc2;
  font-size: 1rem;
}
.dynamic-form .dynamic-form-actions__remove {
  width: 100%;
  float: right;
  margin-top: -0.5rem;
}
.dynamic-form .dynamic-form-actions__remove_trash {
  background: transparent;
  padding: 0.25rem 0.25rem 0 0.25rem;
  font-size: 0.9rem;
}
.dynamic-form .dynamic-form-actions__remove_trash:hover, .dynamic-form .dynamic-form-actions__remove_trash:focus {
  color: #ec2227;
  font-size: 1.25rem;
}
.dynamic-form .action__add_plus, .dynamic-form .action__remove_trash {
  background-color: #fff;
  font-weight: 700;
  text-decoration: underline;
  background: transparent;
  padding-left: 0;
}
.dynamic-form .two_col_layout {
  width: 50%;
  float: left;
  min-height: 4.5625rem;
}
.dynamic-form .one_col_layout {
  width: 100%;
  display: table;
  content: "";
  padding-bottom: 0.5rem;
}
.dynamic-form .three_col_layout {
  width: 33%;
  float: left;
  min-height: 4.5625rem;
}
.dynamic-form .four_col_layout {
  width: 25%;
  float: left;
  min-height: 3.5625rem;
}
.dynamic-form .dynamic-number-field__value-with-add-more, .dynamic-form .dynamic-text-field__value-with-add-more, .dynamic-form .dynamic-text-area-field__value-with-add-more, .dynamic-form .dynamic-single-select__value-with-add-more, .dynamic-form .dynamic-multi-select__value-with-add-more, .dynamic-form .dynamic-date-field__value-with-add-more, .dynamic-form .dynamic-date-range-field__value-with-add-more, .dynamic-form .dynamic-time-field__value-with-add-more, .dynamic-form .dynamic-html-field__value-with-add-more, .dynamic-form .dynamic-selection-grouped__value-with-add-more {
  width: 85%;
  float: left;
  padding-right: 0;
}
.dynamic-form .dynamic-number-field__add-more, .dynamic-form .dynamic-text-field__add-more, .dynamic-form .dynamic-text-area-field__add-more, .dynamic-form .dynamic-single-select__add-more, .dynamic-form .dynamic-multi-select__add-more, .dynamic-form .dynamic-date-field__add-more, .dynamic-form .dynamic-date-range-field__add-more, .dynamic-form .dynamic-time-field__add-more, .dynamic-form .dynamic-html-field__add-more, .dynamic-form .dynamic-selection-grouped__add-more {
  width: 15%;
  float: left;
}
.dynamic-form .group-form__action .dynamic-form-actions {
  width: 100%;
  margin-left: 0;
}
.dynamic-form .group-form__action .dynamic-form-actions .action__add, .dynamic-form .group-form__action .dynamic-form-actions .action__remove {
  width: 50%;
}
.dynamic-form .date-picker__from-date, .dynamic-form .date-picker__to-date, .dynamic-form .date-range-picker__from-date, .dynamic-form .date-range-picker__to-date {
  width: 50%;
  float: left;
}
.dynamic-form .date-picker__from-date, .dynamic-form .date-range-picker__from-date {
  padding-left: 0;
  padding-right: 0.9375rem;
}
.dynamic-form .date-picker__to-date, .dynamic-form .date-range-picker__to-date {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.dynamic-form .dynamic-date-field__add-more {
  padding-top: 1.25rem;
}
.dynamic-form .dynamic-row {
  display: table;
  content: "";
  width: 100%;
  min-height: 1px;
}
.dynamic-form .dynamic-date-range-field__date-range_from-date, .dynamic-form .dynamic-date-range-field__date-range_to-date {
  width: 50%;
  float: left;
}
.dynamic-form .dynamic-date-range-field label {
  color: #087dc2;
}

.important-notice__title {
  color: #087dc2;
  font-size: 1.25rem;
  text-align: center;
  border-bottom: 0.0625rem solid #c7e5f6;
  padding-bottom: 0.5rem;
}
.important-notice__user {
  border-top: 0.0625rem solid #aac7d8;
}
.important-notice__user_name, .important-notice__user_notice {
  margin-left: 1rem;
}
.important-notice__user_name {
  color: #087dc2;
  margin-top: 0.5rem;
}
.important-notice__user_notice {
  margin-bottom: 0.5rem;
}
.important-notice__close-button {
  float: right;
  cursor: pointer;
}

.full-screen-modal:before, .full-screen-modal:after {
  content: " ";
  display: table;
}
.full-screen-modal:after {
  clear: both;
}
.full-screen-modal .modal-dialog {
  margin: auto;
  max-width: 100%;
  height: 100%;
}
.full-screen-modal .modal-dialog .modal-content {
  padding: 0.9375rem;
  min-height: 100%;
  width: 100%;
  padding-bottom: 0;
  padding-top: 0;
  display: inherit;
}
.full-screen-modal .modal-dialog .modal-content:before, .full-screen-modal .modal-dialog .modal-content:after {
  content: " ";
  display: table;
}
.full-screen-modal .modal-dialog .modal-content:after {
  clear: both;
}

.full-screen-modal-body {
  position: relative;
  padding: 15px;
  padding-right: 0;
  padding-left: 0;
}
.full-screen-modal-body:before, .full-screen-modal-body:after {
  content: " ";
  display: table;
}
.full-screen-modal-body:after {
  clear: both;
}
.full-screen-modal-body .modal__content {
  scroll-behavior: smooth;
  padding-right: 0.975rem;
  padding-top: 3.5rem;
  position: relative;
  max-height: calc(105vh - 4rem - 3rem - 2.5rem - 1.875rem);
}
.full-screen-modal-body .modal__content:before, .full-screen-modal-body .modal__content:after {
  content: " ";
  display: table;
}
.full-screen-modal-body .modal__content:after {
  clear: both;
}

.full-screen-modal__content {
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.full-screen-modal__content_loader {
  padding: 15px;
  margin-left: 45%;
}
.full-screen-modal__header {
  color: #087dc2;
  font-size: 0.875rem;
  width: 100%;
  height: 4rem;
}
.full-screen-modal__header:before, .full-screen-modal__header:after {
  content: " ";
  display: table;
}
.full-screen-modal__header:after {
  clear: both;
}
.full-screen-modal__header_client-logo {
  width: 80%;
  float: left;
  margin-top: 0.3125rem;
}
.full-screen-modal__header_client-logo img {
  height: 100%;
}
.full-screen-modal__header_heading {
  width: 100%;
  float: left;
  color: #fff;
  background-color: #087dc2;
  padding: 0.5rem;
  font-weight: 700;
  font-size: 1.125rem;
  text-transform: uppercase;
  height: 2.5rem;
}
.full-screen-modal__header_close {
  width: 20%;
  margin-top: 0.5rem;
  float: right;
  text-align: right;
  cursor: pointer;
  font-size: 1.125rem;
  padding: 0.46875rem;
  padding-right: 0px;
}
.full-screen-modal__header_close .close__icon {
  padding-right: 10px;
}
.full-screen-modal__header_info {
  margin-top: 7px;
  font-size: 1.125rem;
  background-color: #eaf5fc;
  height: 35px;
  padding: 5px 5px 5px 7px;
}

.full-screen-modal-heading__main:before, .full-screen-modal-heading__main:after {
  content: " ";
  display: table;
}
.full-screen-modal-heading__main:after {
  clear: both;
}

@media print {
  .modal-open .full-screen-modal {
    position: absolute;
    overflow: visible;
    -webkit-print-color-adjust: exact;
    width: 98%;
  }

  .full-screen-modal__header_close {
    display: none;
  }

  .full-screen-modal__header_heading {
    -webkit-print-color-adjust: exact;
    font-weight: 700;
  }

  .main__result {
    display: none;
  }
}
.radio-component {
  font-size: 1.25rem;
}
.radio-component input[type=radio] {
  display: none;
}
.radio-component input[type=radio] + label {
  cursor: pointer;
}
.radio-component input[type=radio]:checked + label {
  color: #087dc2;
}
.radio-component input[type=radio]:checked + label::before {
  border: 2px solid #087dc2;
  background: #087dc2;
  box-shadow: inset 0 0 0 3px #fff;
}
.radio-component input[type=radio] + label::before {
  display: inline-block;
  content: "";
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.625rem;
  border: 2px solid #bfbfbf;
  border-radius: 50%;
  transition: all 0.25s linear;
}

multi-image-carousel-display {
  display: block;
}

.multi-image-carousel {
  position: relative;
  margin: auto;
  padding-right: 0.95rem;
  height: 110px;
  overflow: hidden;
  max-width: 1024px;
}
@media (min-width: 1280px) {
  .multi-image-carousel {
    max-width: 1280px;
  }
}
@media (min-width: 1600px) {
  .multi-image-carousel {
    max-width: 1600px;
  }
}
.multi-image-carousel__prev-btn, .multi-image-carousel__next-btn {
  height: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 2rem;
  cursor: pointer;
}
.multi-image-carousel__prev-btn .fa, .multi-image-carousel__next-btn .fa {
  color: #000;
  font-size: 2.35rem;
  position: absolute;
  top: 35%;
}
.multi-image-carousel__prev-btn {
  left: 0;
}
.multi-image-carousel__prev-btn .fa {
  left: 0.25rem;
}
.multi-image-carousel__next-btn {
  right: 0;
}
.multi-image-carousel__next-btn .fa {
  right: 0.25rem;
}
.multi-image-carousel .multi-image-carousel-container {
  margin-left: 0.8rem;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}
.multi-image-carousel .multi-image-carousel-container__content {
  height: 200px;
  transition: all ease-in-out 0.5s;
}
.multi-image-carousel .multi-image-carousel-container__slide {
  height: 150px;
  float: left;
}
.multi-image-carousel .multi-slide-carousel-slide-container {
  max-height: 135px;
  height: 100%;
  padding: 1rem 1rem 1.8rem 1rem;
  text-align: center;
}
.multi-image-carousel .multi-slide-carousel-slide-container__slide {
  width: 150px;
  height: 100%;
  border: 2px solid #fff;
  opacity: 0.5;
}
.multi-image-carousel .multi-slide-carousel-slide-container__slide:hover {
  opacity: 1;
}
.multi-image-carousel .active__img .multi-slide-carousel-slide-container__slide {
  opacity: 1;
  border-color: #087dc2;
}

.action-modal {
  max-width: 40%;
  margin-left: 30%;
  margin-top: 10%;
  font-size: 0.75rem;
}

.action-modal-container {
  width: 300px;
  margin: 0 auto;
  padding: 1rem;
}
.action-modal-container__message {
  color: #087dc2;
  padding-bottom: 0.625rem;
  margin-left: -20%;
  margin-right: -20%;
  text-align: center;
  font-size: 1.2rem;
}
.action-modal-container__action-button-container {
  width: 100%;
}
.action-modal-container__action-button-container:before, .action-modal-container__action-button-container:after {
  content: " ";
  display: table;
}
.action-modal-container__action-button-container:after {
  clear: both;
}
.action-modal-container__action-button-container_approve-button, .action-modal-container__action-button-container_reject-button {
  padding: 0.3125rem 0;
  font-size: 10px;
  line-height: inherit;
  border-radius: 0;
  color: #087dc2;
  background-color: #c7e5f6;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  width: 40%;
  float: left;
  margin-left: 10%;
  cursor: pointer;
}
.action-modal-container__action-button-container_approve-button:focus, .action-modal-container__action-button-container_approve-button.focus, .action-modal-container__action-button-container_approve-button:active:focus, .action-modal-container__action-button-container_approve-button:active.focus, .action-modal-container__action-button-container_approve-button.active:focus, .action-modal-container__action-button-container_approve-button.active.focus, .action-modal-container__action-button-container_reject-button:focus, .action-modal-container__action-button-container_reject-button.focus, .action-modal-container__action-button-container_reject-button:active:focus, .action-modal-container__action-button-container_reject-button:active.focus, .action-modal-container__action-button-container_reject-button.active:focus, .action-modal-container__action-button-container_reject-button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.action-modal-container__action-button-container_approve-button:hover, .action-modal-container__action-button-container_approve-button:focus, .action-modal-container__action-button-container_approve-button.focus, .action-modal-container__action-button-container_reject-button:hover, .action-modal-container__action-button-container_reject-button:focus, .action-modal-container__action-button-container_reject-button.focus {
  color: #000;
  text-decoration: none;
  background-color: #6fbce8;
}
.action-modal-container__action-button-container_approve-button:active, .action-modal-container__action-button-container_approve-button.active, .action-modal-container__action-button-container_reject-button:active, .action-modal-container__action-button-container_reject-button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.action-modal-container__action-button-container_approve-button.disabled, .action-modal-container__action-button-container_approve-button[disabled], fieldset[disabled] .action-modal-container__action-button-container_approve-button, .action-modal-container__action-button-container_reject-button.disabled, .action-modal-container__action-button-container_reject-button[disabled], fieldset[disabled] .action-modal-container__action-button-container_reject-button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.phone-number-component {
  line-height: normal;
}
.phone-number-component__country-code {
  padding-right: 0.25rem;
  width: 30%;
  float: left;
}
.phone-number-component .phone-num-with-country-code {
  width: 70%;
  float: right;
}
.phone-number-component .phone-num-without-country-code {
  width: 100%;
}
.phone-number-component .phone-number-input {
  width: 100%;
  padding: 0.5rem 1rem;
  max-height: 34px;
}

.toggle-display-container {
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.toggle-display-container__header {
  color: #087dc2;
}
.toggle-display-container__header_close {
  cursor: pointer;
  float: right;
}

.selected-client-info {
  margin-top: 0.25rem;
  margin-right: 0.25rem;
}
.selected-client-info:before, .selected-client-info:after {
  content: " ";
  display: table;
}
.selected-client-info:after {
  clear: both;
}
.selected-client-info__cpg-balance {
  margin-right: 0.25rem;
}
.selected-client-info__client_name {
  float: right;
  margin-top: 0.25rem;
  padding-right: 0.625rem;
}
.selected-client-info__client_name:hover {
  cursor: pointer;
}
.selected-client-info__client_name .login__popover_user-id-arrow {
  margin-top: 0.25rem;
}
.selected-client-info__client_name .popover-content {
  padding: 0px;
}
.selected-client-info__balance_value {
  font-family: "Open Sans";
}
.selected-client-info__balance_refresh-icon:hover {
  cursor: pointer;
}

.daily-cash-limit {
  display: flex;
}
.daily-cash-limit:before, .daily-cash-limit:after {
  content: " ";
  display: table;
}
.daily-cash-limit:after {
  clear: both;
}
.daily-cash-limit__balance {
  display: flex;
}
.daily-cash-limit__balance_air-limit {
  margin-right: 0.25rem;
}
.daily-cash-limit__balance_air-limit .air-limit__value,
.daily-cash-limit__balance_air-limit .non-air-limit__value, .daily-cash-limit__balance_non-air-limit .air-limit__value,
.daily-cash-limit__balance_non-air-limit .non-air-limit__value {
  font-family: "Open Sans";
}
.daily-cash-limit__refresh-icon {
  margin-left: 0.25rem;
}
.daily-cash-limit__refresh-icon:hover {
  cursor: pointer;
}

.email-result__page__header:before, .email-result__page__header:after {
  content: " ";
  display: table;
}
.email-result__page__header:after {
  clear: both;
}
.email-result__page__header_text {
  color: #838383;
  padding-left: 0px;
  font-size: 12px;
  float: left;
}
.email-result__page__control-buttons {
  width: 100%;
  margin: 0 auto;
}
.email-result__page__control-buttons:before, .email-result__page__control-buttons:after {
  content: " ";
  display: table;
}
.email-result__page__control-buttons:after {
  clear: both;
}
.email-result__page__control-buttons .control-buttons__send-email {
  color: #fff;
  background-color: #ec2227;
  margin-top: 1.525rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-weight: 700;
  width: 100%;
}
.email-result__page__control-buttons .control-buttons__send-email:focus, .email-result__page__control-buttons .control-buttons__send-email.focus, .email-result__page__control-buttons .control-buttons__send-email:active:focus, .email-result__page__control-buttons .control-buttons__send-email:active.focus, .email-result__page__control-buttons .control-buttons__send-email.active:focus, .email-result__page__control-buttons .control-buttons__send-email.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.email-result__page__control-buttons .control-buttons__send-email:hover, .email-result__page__control-buttons .control-buttons__send-email:focus, .email-result__page__control-buttons .control-buttons__send-email.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.email-result__page__control-buttons .control-buttons__send-email:active, .email-result__page__control-buttons .control-buttons__send-email.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.email-result__page__control-buttons .control-buttons__send-email.disabled, .email-result__page__control-buttons .control-buttons__send-email[disabled], fieldset[disabled] .email-result__page__control-buttons .control-buttons__send-email {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.email-result__page label {
  margin-top: 10px;
  color: #087dc2;
  font-size: 0.875rem;
  width: 100%;
}
.email-result__page input[type=email], .email-result__page input[type=text] {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  color: #838383;
  max-height: 34px;
}
.email-result__page input[type=email]:disabled, .email-result__page input[type=text]:disabled {
  background-color: #f7f7f9;
}
.email-result__page textarea {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  height: 70px;
  color: #838383;
}
.email-result__page__alert_message {
  width: 100%;
}
.email-result__page__alert_message:before, .email-result__page__alert_message:after {
  content: " ";
  display: table;
}
.email-result__page__alert_message:after {
  clear: both;
}
.email-result__page .alert__message--error, .email-result__page .alert__message--success {
  margin-top: 2rem;
  width: 100%;
}
.email-result__page .form-field-validation-error {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.email-result-modal .modal-dialog {
  width: 40%;
  padding-top: 5.525rem;
}

.card__send-email .email-result__page__alert_message {
  display: table;
  width: 100%;
}
.card__send-email .email-result__page__alert_message:before, .card__send-email .email-result__page__alert_message:after {
  content: " ";
  display: table;
}
.card__send-email .email-result__page__alert_message:after {
  clear: both;
}
.card__send-email .email-result__page__control-buttons {
  width: 13rem;
  float: right;
  padding-bottom: 1rem;
  padding-right: 2rem;
}
.card__send-email .email-result__page__control-buttons button {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  color: #fff;
  background-color: #087dc2;
}
.card__send-email .email-result__page__control-buttons button:focus, .card__send-email .email-result__page__control-buttons button.focus, .card__send-email .email-result__page__control-buttons button:active:focus, .card__send-email .email-result__page__control-buttons button:active.focus, .card__send-email .email-result__page__control-buttons button.active:focus, .card__send-email .email-result__page__control-buttons button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.card__send-email .email-result__page__control-buttons button:hover, .card__send-email .email-result__page__control-buttons button:focus, .card__send-email .email-result__page__control-buttons button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #043e60;
}
.card__send-email .email-result__page__control-buttons button:active, .card__send-email .email-result__page__control-buttons button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.card__send-email .email-result__page__control-buttons button.disabled, .card__send-email .email-result__page__control-buttons button[disabled], fieldset[disabled] .card__send-email .email-result__page__control-buttons button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card__send-email .email-result__page__control-buttons-container {
  display: table;
  width: 100%;
}
.card__send-email .email-results_form {
  display: table;
  width: 100%;
}
.card__send-email .email-results_form__fields {
  display: table;
  width: 100%;
  padding-top: 1rem;
}
.card__send-email .email-results_form__fields .form-field-validation-error {
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #ec2227;
}
.card__send-email .email-results_form__fields .form-field-validation-error .display-error-message {
  position: relative;
  left: 5%;
}
.card__send-email .email-results_form .email-field-check {
  padding-left: 2.5rem;
}
.card__send-email .email-results_form .email-field-check:before, .card__send-email .email-results_form .email-field-check:after {
  content: " ";
  display: table;
}
.card__send-email .email-results_form .email-field-check:after {
  clear: both;
}
.card__send-email .email-results_form .email-field-check_input-checkbox {
  float: left;
  padding: 0 0.25rem;
}
.card__send-email .email-results_form .email-field-check_label {
  float: left;
  padding-left: 0.25rem;
  line-height: 1.75rem;
}
.card__send-email .fields_label {
  float: left;
  display: table;
  width: 5%;
}
.card__send-email .fields_input {
  display: table;
  width: 50%;
}
.card__send-email .fields_input .ui-select-multiple {
  height: 3rem;
}

.quotation-acceptance-wrapper {
  height: 100%;
  width: 100%;
  background: #fff;
}
.quotation-acceptance-wrapper:before, .quotation-acceptance-wrapper:after {
  content: " ";
  display: table;
}
.quotation-acceptance-wrapper:after {
  clear: both;
}

.rfq-quotation-acceptance {
  height: 100%;
  overflow-y: scroll;
}
.rfq-quotation-acceptance__container_description {
  padding-top: 2.5rem;
}
.rfq-quotation-acceptance__container_description .description-note {
  position: relative;
  left: 5%;
}
.rfq-quotation-acceptance__header {
  content: "";
  display: table;
  border-bottom: 1px solid #087dc2;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}
.rfq-quotation-acceptance__header_logo {
  float: left;
  width: 30%;
  height: 3rem;
}
.rfq-quotation-acceptance__header_logo .title-logo {
  content: "";
  display: table;
  position: relative;
  left: 4rem;
  height: 100%;
  width: 8rem;
}
.rfq-quotation-acceptance__header_logo .title-logo img {
  height: 100%;
  width: 100%;
}
.rfq-quotation-acceptance__header_title {
  float: right;
  font-size: 1.5rem;
  color: #087dc2;
  text-transform: uppercase;
  width: 70%;
  text-align: right;
  position: relative;
  top: 1rem;
}
.rfq-quotation-acceptance__header_title .title_description {
  position: relative;
  right: 5%;
}
.rfq-quotation-acceptance__container_acceptance-form {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
  width: 90%;
  margin: 5%;
  margin-top: 2rem;
  background: #fff;
  padding: 0;
}
.rfq-quotation-acceptance__container_acceptance-form__header {
  padding-bottom: 0.4375rem;
  border-bottom: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-header {
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  background-image: linear-gradient(75deg, #087dc2 20%, #f0f5f8 90%);
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-header_title {
  color: #fff;
  content: "";
  display: table;
  text-transform: uppercase;
  padding-top: 0.375rem;
  font-size: 0.875rem;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  text-align: center;
  position: relative;
  left: 3rem;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product {
  padding: 0.65rem;
  min-height: 100px;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_row {
  width: 100%;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_row .row-checkbox, .rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_row .row-details {
  content: "";
  display: table;
  float: left;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_row .row-checkbox {
  width: 5%;
  height: 100%;
  text-align: center;
  font-size: 1rem;
  color: #838383;
  padding-top: 2.25rem;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_row .row-details {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
  width: 95%;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  content: "";
  display: table;
  padding: 0;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_row .row-details__header {
  padding-bottom: 0.4375rem;
  border-bottom: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_btn-container {
  content: "";
  display: table;
  width: 100%;
  padding-top: 0.5rem;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_btn-container .btn-accept, .rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_btn-container .cancel-btn {
  padding: 0.35rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #c7e5f6;
  color: #087dc2;
  float: right;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_btn-container .btn-accept {
  background: #ec2227;
  color: #fff;
  width: 25%;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_btn-container .btn-accept-disabled {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_btn-container .btn-accept:hover {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_terms-and-condition-container {
  width: 100%;
  padding: 0.95rem 0 0 1.25rem;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_terms-and-condition-container .terms-and-condition {
  position: relative;
  left: 1.35rem;
}
.rfq-quotation-acceptance__container_acceptance-form .acceptance-form-product_terms-and-condition-container .terms-and-condition .terms__checkbox {
  cursor: pointer;
}

.quotation-header {
  background: #087dc2;
}

.product-details {
  width: 100%;
  padding-bottom: 0.5rem;
}
.product-details:before, .product-details:after {
  content: " ";
  display: table;
}
.product-details:after {
  clear: both;
}
.product-details__action-buttons {
  margin-top: 0.5rem;
}
.product-details__product-container {
  width: 100%;
  font-size: 1rem;
  border-bottom: 1px solid #c7e5f6;
  color: #55595c;
  min-height: 90px;
}
.product-details__product-container:before, .product-details__product-container:after {
  content: " ";
  display: table;
}
.product-details__product-container:after {
  clear: both;
}
.product-details__product-container-header {
  width: 100%;
  display: table;
  content: "";
  padding: 0.65rem;
  min-height: 50px;
}
.product-details__product-container-header_content-tab {
  float: left;
  margin-top: 0.375rem;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  width: 30%;
}
.product-details__product-container-header_content-tab .elipses-active {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product-details__product-container-header_content-tab .tooltip-product-desc .tooltip-inner {
  background: #fff;
  color: #55595c;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
  padding-top: 0.75rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.75rem;
  min-width: 390px;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;
}
.product-details__product-container-header_content-tab .tooltip-product-desc .tooltip-arrow {
  border-top-color: #fff;
}
.product-details__product-container-header_icon {
  float: left;
  font-size: 1.25rem;
  width: 5%;
}
.product-details__product-container-header_booking-status {
  width: 10%;
  font-family: "Open Sans";
  float: left;
  width: 15%;
  margin-top: 0.375rem;
}
.product-details__product-container-header_price-details {
  width: 15%;
  padding: 0rem;
  text-align: center;
  margin-top: 0rem;
  font-family: "Open Sans";
  width: 20%;
}
.product-details__product-container-header_price-details .price-details__amount {
  color: #ec2227;
  font-size: 1.25rem;
}
.product-details__product-container-header_price-details .price-details__currency {
  font-size: 0.875rem;
  margin-top: 0.3rem;
}
.product-details__product-container-footer {
  content: "";
  display: table;
  min-height: 40px;
  width: 100%;
  background: #e9f1f6;
}
.product-details__product-container-footer_extras {
  float: right;
  margin: 0;
  font-size: 1.75rem;
  padding: 0 1rem;
}

.quotation-acceptance-remark__description {
  padding: 0.5rem;
}

.tax-breakup_card_header {
  text-transform: uppercase;
  color: #087dc2;
}
.tax-breakup_card .header_text {
  margin-right: 1.875rem;
}
.tax-breakup__table {
  padding-left: 5px;
  padding-right: 5px;
}
.tax-breakup__table > table {
  width: 100%;
  font-family: "Open Sans";
}
.tax-breakup__table > table > thead {
  text-transform: uppercase;
  background-color: #f0f5f8;
}
.tax-breakup__table > table > thead th:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  text-align: left;
}
.tax-breakup__table > table > thead th {
  padding: 5px;
  text-align: right;
}
.tax-breakup__table > table > tbody {
  margin-top: 15px;
}
.tax-breakup__table > table > tbody > tr {
  border-bottom: 2px solid #f0f5f8;
}
.tax-breakup__table > table > tbody > tr td {
  padding: 5px;
  text-align: right;
  color: #087dc2;
  text-transform: none;
}
.tax-breakup__table > table > tbody > tr td:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  color: #838383;
}
.tax-breakup__table > table > tbody > tr:nth-child(2) td {
  text-transform: none;
}
.tax-breakup__table > table > tbody tr:last-child {
  background-color: #f0f5f8;
}
.tax-breakup__table > table > tbody tr:last-child td {
  padding: 5px;
  text-align: right;
  color: #000;
}
.tax-breakup__table > table > tbody tr:last-child td:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  text-transform: none;
}

.review-fare-details_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.review-fare-details_card:before, .review-fare-details_card:after {
  content: " ";
  display: table;
}
.review-fare-details_card:after {
  clear: both;
}
.review-fare-details_card_header {
  padding: 0.375rem 0.9375rem;
}
.review-fare-details_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.review-fare-details_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.review-fare-details_header ul:before, .review-fare-details_header ul:after {
  content: " ";
  display: table;
}
.review-fare-details_header ul:after {
  clear: both;
}
.review-fare-details_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.review-fare-details_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.review-fare-details_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.review-fare-details_card_content {
  margin-bottom: 0.9375rem;
}
.review-fare-details_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.review-fare-details_card_header {
  text-transform: uppercase;
  color: #087dc2;
  padding-left: 0px;
}
.review-fare-details_card .header_text {
  margin-right: 1.875rem;
}
.review-fare-details__table_price-breakup {
  float: left;
  text-transform: uppercase;
  padding: 0.375rem 0;
}
.review-fare-details__table_price-adhoc-amount {
  padding: 0.375rem 0;
  float: right;
}
.review-fare-details__table table {
  width: 100%;
  font-family: "Open Sans";
}
.review-fare-details__table table thead {
  text-transform: uppercase;
  background-color: #f0f5f8;
}
.review-fare-details__table table thead th:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  text-align: left;
}
.review-fare-details__table table thead th {
  padding: 5px;
  text-align: right;
}
.review-fare-details__table table tbody tr {
  border-bottom: 2px solid #f0f5f8;
}
.review-fare-details__table table tbody tr td {
  padding: 5px;
  text-align: right;
  color: #087dc2;
}
.review-fare-details__table table tbody tr td:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  color: #838383;
}

.total-amount,
.grand-total-amount {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  text-transform: uppercase;
}
.total-amount__value, .total-amount__result-page-value,
.grand-total-amount__value,
.grand-total-amount__result-page-value {
  float: right;
  color: #087dc2;
}
.total-amount__result-page-value,
.grand-total-amount__result-page-value {
  padding-right: 1.7rem;
  color: inherit;
}

.grand-total-amount {
  background-color: #f0f5f8;
  color: #000;
}
.grand-total-amount__value, .grand-total-amount__result-page-value {
  color: #000;
}

.flight__review_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.flight__review_main:before, .flight__review_main:after {
  content: " ";
  display: table;
}
.flight__review_main:after {
  clear: both;
}
.flight__review_main .content {
  padding-left: 0;
}
.flight__review_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .flight__review_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .flight__review_wrapper {
    max-width: 1400px;
  }
}
.flight__review_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.flight__review_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.flight__review_row:before, .flight__review_row:after {
  content: " ";
  display: table;
}
.flight__review_row:after {
  clear: both;
}
.flight__review_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.flight__review_card:before, .flight__review_card:after {
  content: " ";
  display: table;
}
.flight__review_card:after {
  clear: both;
}
.flight__review_card_header {
  padding: 0.375rem 0.9375rem;
}
.flight__review_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.flight__review_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.flight__review_header ul:before, .flight__review_header ul:after {
  content: " ";
  display: table;
}
.flight__review_header ul:after {
  clear: both;
}
.flight__review_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.flight__review_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.flight__review_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.flight__review_card_content {
  margin-bottom: 0.9375rem;
}
.flight__review_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.flight__review_footer:before, .flight__review_footer:after {
  content: " ";
  display: table;
}
.flight__review_footer:after {
  clear: both;
}
.flight__review_flight {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.flight__review_from, .flight__review_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.flight__review_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.flight__review_main {
  padding-top: 0.9375rem;
}
.flight__review_main .content {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.flight__review_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.flight__review_card .pnr-fare-selection__table_price-breakup .review-fare-details_card_header {
  margin-bottom: 3%;
}
.flight__review_card .pnr-fare-selection__trip_description {
  float: left;
}
.flight__review_card .pnr-fare-selection__select_btn {
  float: right;
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 18%;
  font-weight: 700;
  text-transform: uppercase;
}
.flight__review_card .pnr-fare-selection__select_btn:focus, .flight__review_card .pnr-fare-selection__select_btn.focus, .flight__review_card .pnr-fare-selection__select_btn:active:focus, .flight__review_card .pnr-fare-selection__select_btn:active.focus, .flight__review_card .pnr-fare-selection__select_btn.active:focus, .flight__review_card .pnr-fare-selection__select_btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.flight__review_card .pnr-fare-selection__select_btn:hover, .flight__review_card .pnr-fare-selection__select_btn:focus, .flight__review_card .pnr-fare-selection__select_btn.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.flight__review_card .pnr-fare-selection__select_btn:active, .flight__review_card .pnr-fare-selection__select_btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.flight__review_card .pnr-fare-selection__select_btn.disabled, .flight__review_card .pnr-fare-selection__select_btn[disabled], fieldset[disabled] .flight__review_card .pnr-fare-selection__select_btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.flight__review_card .pnr-fare-selection__fare-category-img-container {
  padding-right: 5px;
}
.flight__review_card .pnr-fare-selection__fare-category-img-container img {
  height: 20px;
}
.flight__review_card_header {
  text-transform: uppercase;
  color: #087dc2;
  padding-left: 0px;
}
.flight__review_card .header_text {
  margin-right: 0.234375rem;
  font-size: 0.9rem;
}
.flight__review_card .header_legs {
  position: relative;
}
.flight__review_card .header_legs:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 1px;
  background-color: #087dc2;
  left: 100%;
  top: 50%;
  margin-top: -1px;
}
.flight__review_card .header_legs:last-child:after {
  content: none;
}
.flight__review_card .nav {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  background-color: #c7e5f6;
}
.flight__review_card .nav-tabs {
  border-bottom: none;
}
.flight__review_card .nav-tabs > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  color: #087dc2;
  font-size: 0.875rem;
}
.flight__review_card .nav > li > a:hover,
.flight__review_card .nav > li > a:focus {
  background-color: #fff;
}
.flight__review_card .nav-tabs > li.active > a,
.flight__review_card .nav-tabs > li.active > a:hover,
.flight__review_card .nav-tabs > li.active > a:focus {
  color: #087dc2;
  background-color: #fff;
  border: none;
  cursor: default;
}

.review__flight, .review__from, .review__to, .review__duration, .review__price {
  padding-top: 0.9375rem;
}
.review__fare-basis {
  color: #087dc2;
}
.review__fare-rules, .review__fare-basis {
  font-size: 0.625rem;
}
.review__flight, .review__duration {
  text-align: center;
}
.review__from {
  text-align: left;
}
.review__to {
  text-align: right;
}
.review__from_airport, .review__from_terminal, .review__from_baggage, .review__from_aircarft-type, .review__to_airport, .review__to_terminal, .review__to_baggage, .review__to_aircarft-type {
  font-size: 0.625rem;
  word-wrap: break-word;
}
.review__from_airline, .review__to_airline {
  font-size: 0.625rem;
}
.review__from_location, .review__to_location {
  text-transform: uppercase;
  color: #087dc2;
}
.review__from_aircraft, .review__to_aircraft {
  text-transform: uppercase;
  color: #087dc2;
}
.review__from_time, .review__from_date, .review__to_time, .review__to_date {
  font-family: "Open Sans";
}
.review__from_date, .review__to_date {
  font-size: 0.875rem;
  color: #838383;
}
.review__from_time, .review__to_time {
  font-size: 1.125rem;
  color: #087dc2;
  line-height: 1.125rem;
}
.review__from_time .to-iata, .review__from_time .from-iata, .review__to_time .to-iata, .review__to_time .from-iata {
  width: 100%;
}
.review__from_suitcase, .review__to_suitcase {
  color: #087dc2;
  font-size: 1.2rem;
}
.review__fid {
  font-family: "Open Sans";
}
.review__airline-xid {
  font-family: "Open Sans";
}
.review__duration_flight-duration {
  font-size: 0.5625rem;
}
.review__duration .flexi-time {
  font-size: 0.75rem;
  color: #55595c;
}
.review__duration .flexi-time:before, .review__duration .flexi-time:after {
  width: 1.0625rem;
  border: 0.0625rem #b6b6b6 dashed;
  background: #fff;
}
.review__duration_time {
  font-size: 1.125rem;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.125rem;
  color: #f48083;
  position: relative;
  text-transform: lowercase;
}
.review__duration_time:before, .review__duration_time:after {
  width: 60px;
  content: "";
  height: 1px;
  background-color: #d0d0d0;
  position: absolute;
  top: 50%;
}
.review__duration_time:before {
  right: 100%;
}
.review__duration_time:after {
  left: 100%;
}
.review__duration_wrapper {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
  padding-left: 0;
  padding-right: 0;
}
.review__duration_wrapper:before, .review__duration_wrapper:after {
  content: " ";
  display: table;
}
.review__duration_wrapper:after {
  clear: both;
}
.review__duration_duration-block {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.85rem;
}
.review__seat_count_red {
  color: #ec2227;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Open Sans";
}
.review__seat_count_green {
  color: #908e8e;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Open Sans";
}
.review__seat_text {
  color: #087dc2;
  text-transform: capitalize;
}

.details__details_layover, .details__details_return {
  position: relative;
  text-align: center;
}
.details__details_layover:after, .details__details_return:after {
  position: absolute;
  width: 50%;
  height: 1px;
  top: 50%;
  margin-left: 25%;
  content: "";
}
.details__details_layover {
  font-family: "Open Sans";
}
.details__details_return {
  color: #087dc2;
}
.details__details_layover:after {
  background-color: #b6b6b6;
}
.details__details_return:after {
  background-color: #c7e5f6;
}

hr.legs-seperator {
  position: absolute;
  /* display: inline; */
  /* float: left; */
  width: 110px;
  height: 1px;
  background-color: red;
  top: 0;
  left: 50%;
  z-index: -1;
  margin-left: -55px;
}

.dynamic-pax-info-form {
  margin-bottom: 0.5rem;
}
.dynamic-pax-info-form__content:before, .dynamic-pax-info-form__content:after {
  content: " ";
  display: table;
}
.dynamic-pax-info-form__content:after {
  clear: both;
}
.dynamic-pax-info-form__link {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  cursor: pointer;
  text-align: left;
  color: #087dc2;
}
.dynamic-pax-info-form .dynamic-form {
  width: 100%;
}
.dynamic-pax-info-form .dynamic-form:before, .dynamic-pax-info-form .dynamic-form:after {
  content: " ";
  display: table;
}
.dynamic-pax-info-form .dynamic-form:after {
  clear: both;
}
.dynamic-pax-info-form .dynamic-form .dynamic-grouped-form {
  border: none;
  padding: 0px;
  min-height: 5rem;
}
.dynamic-pax-info-form .dynamic-form .dynamic-selection-grouped {
  width: 100%;
  display: table;
  content: "";
  padding: 0rem;
}
.dynamic-pax-info-form .dynamic-form .dynamic-selection-grouped .dynamic-single-select-content {
  width: 24%;
}
.dynamic-pax-info-form .dynamic-form .dynamic-single-select select,
.dynamic-pax-info-form .dynamic-form .dynamic-multi-select select,
.dynamic-pax-info-form .dynamic-form .dynamic-date-field select,
.dynamic-pax-info-form .dynamic-form .date-field select,
.dynamic-pax-info-form .dynamic-form .dynamic-date-range-field select,
.dynamic-pax-info-form .dynamic-form .dynamic-selection-grouped select {
  border: 1px solid;
  border-color: #d0d0d0;
}
.dynamic-pax-info-form .dynamic-form .dynamic-single-select {
  min-height: 3.5625rem;
}
.dynamic-pax-info-form .dynamic-form .four_col_layout {
  padding-right: 0.5rem;
}
.dynamic-pax-info-form .dynamic-form .dynamic-number-field,
.dynamic-pax-info-form .dynamic-form .dynamic-text-field,
.dynamic-pax-info-form .dynamic-form .dynamic-text-area-field,
.dynamic-pax-info-form .dynamic-form .dynamic-single-select,
.dynamic-pax-info-form .dynamic-form .dynamic-multi-select,
.dynamic-pax-info-form .dynamic-form .dynamic-date-field,
.dynamic-pax-info-form .dynamic-form .date-field,
.dynamic-pax-info-form .dynamic-form .dynamic-date-range-field,
.dynamic-pax-info-form .dynamic-form .dynamic-time-field,
.dynamic-pax-info-form .dynamic-form .dynamic-html-field,
.dynamic-pax-info-form .dynamic-form .dynamic-selection-grouped {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 4rem;
}
.dynamic-pax-info-form .dynamic-form .dynamic-number-field__value,
.dynamic-pax-info-form .dynamic-form .dynamic-text-field__value,
.dynamic-pax-info-form .dynamic-form .dynamic-text-area-field__value,
.dynamic-pax-info-form .dynamic-form .dynamic-single-select__value,
.dynamic-pax-info-form .dynamic-form .dynamic-multi-select__value,
.dynamic-pax-info-form .dynamic-form .dynamic-date-field__value,
.dynamic-pax-info-form .dynamic-form .date-field__value,
.dynamic-pax-info-form .dynamic-form .dynamic-date-range-field__value,
.dynamic-pax-info-form .dynamic-form .dynamic-time-field__value,
.dynamic-pax-info-form .dynamic-form .dynamic-html-field__value,
.dynamic-pax-info-form .dynamic-form .dynamic-selection-grouped__value {
  padding-right: 0;
}
.dynamic-pax-info-form .dynamic-form .dynamic-phone-number__label {
  margin-bottom: 0;
  color: #087dc2;
  font-size: 0.875rem;
}
.dynamic-pax-info-form .dynamic-form .dynamic-phone-number__country-code {
  width: 30%;
  float: left;
  padding-right: 0.5rem;
}
.dynamic-pax-info-form .dynamic-form .dynamic-phone-number__country-code input[type=text] {
  padding: 0.5rem;
}
.dynamic-pax-info-form .dynamic-form .dynamic-phone-number__mobile-number {
  width: 70%;
  float: left;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  max-height: 2.125rem;
}
.dynamic-pax-info-form .dynamic-form .form-field-validation-error {
  padding-top: 0.3125rem;
}
.dynamic-pax-info-form .dynamic-form .itemcontainer {
  max-height: 16.25rem;
  overflow-y: scroll;
  z-index: 5;
  position: absolute;
  background-color: #fff;
  border-top: 1px solid #087dc2;
  border-right: 1px solid #eceeef;
  border-bottom: 1px solid #eceeef;
  border-left: 1px solid #eceeef;
  width: 100%;
}
.dynamic-pax-info-form .dynamic-form .itemcontainer__item_title {
  margin-bottom: 0;
  border-bottom: 1px solid #eceeef;
  padding-top: 1rem;
  padding-bottom: 0.9375rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.dynamic-pax-info-form .dynamic-form .itemcontainer__item_title:before, .dynamic-pax-info-form .dynamic-form .itemcontainer__item_title:after {
  content: " ";
  display: table;
}
.dynamic-pax-info-form .dynamic-form .itemcontainer__item_title:after {
  clear: both;
}
.dynamic-pax-info-form .dynamic-form .itemcontainer__item_title > .autosuggest-country {
  float: right;
}
.dynamic-pax-info-form .dynamic-form .itemcontainer__item:last-child p:last-child {
  border-bottom: none;
}
.dynamic-pax-info-form .dynamic-form .four_col_layout {
  min-height: 5rem;
}
.dynamic-pax-info-form__header {
  width: 100%;
  background-color: #f0f5f8;
}
.dynamic-pax-info-form__header:before, .dynamic-pax-info-form__header:after {
  content: " ";
  display: table;
}
.dynamic-pax-info-form__header:after {
  clear: both;
}
.dynamic-pax-info-form__header_title {
  line-height: 1.428571429;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-align: center;
  color: #087dc2;
  font-size: 1.25rem;
  margin: 0;
  width: 95%;
  float: left;
}
.dynamic-pax-info-form__header_close-icon {
  cursor: pointer;
  float: right;
}
.dynamic-pax-info-form__heading-container {
  display: inline-table;
  width: 100%;
  border-bottom: 1px solid #c7e5f6;
}
.dynamic-pax-info-form__heading-container:before, .dynamic-pax-info-form__heading-container:after {
  content: " ";
  display: table;
}
.dynamic-pax-info-form__heading-container:after {
  clear: both;
}
.dynamic-pax-info-form__heading-container_heading, .dynamic-pax-info-form__heading-container_close-button {
  font-size: 1.25rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
dynamic-default-form__text-field .dynamic-pax-info-form__heading-container_heading {
  width: 90%;
  color: #087dc2;
  text-align: center;
  float: left;
}
.dynamic-pax-info-form__heading-container_close-button {
  text-align: right;
  cursor: pointer;
  float: right;
}
.dynamic-pax-info-form__summary {
  padding: 0.725rem;
  border: 10px solid #c7e5f6;
  margin-bottom: 0.725rem;
  font-family: "Open Sans";
}
.dynamic-pax-info-form__content-disable {
  cursor: not-allowed;
}
.dynamic-pax-info-form .dynamic-default-form__auto-suggestion {
  position: relative;
}
.dynamic-pax-info-form__checkbox {
  color: #087dc2;
  font-weight: 700;
  float: right;
  text-align: right;
}
.dynamic-pax-info-form__checkbox .checkbox-component {
  font-size: 0.875rem;
}

.product-notification {
  margin: 0.75rem 0;
}
.product-notification__summary {
  display: flex;
  padding: 1rem;
  background: #e6e6e6;
  border-left: 5px solid #55595c;
  justify-content: space-between;
}
.product-notification__summary_message {
  color: #55595c;
  font-weight: bold;
}
.product-notification__summary_expander {
  border-radius: 50%;
  background-color: #bfbfbf;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}
.product-notification__container_notification {
  display: flex;
  padding: 1rem;
  padding-bottom: 0;
  background: #fafafa;
  justify-content: space-between;
  flex-direction: column;
}
.product-notification__container_notification .alert__message {
  margin-bottom: 0.25rem;
}
.product-notification__container_notification:last-child {
  padding-bottom: 1rem;
}
.product-notification__container .notification__type {
  font-weight: bold;
}
.product-notification__container .notification__type-error {
  color: #ec2227;
}
.product-notification__container .notification__type-warning {
  color: #8a6d3b;
}
.product-notification__container .notification__type-info {
  color: #087dc2;
}

.cookie-consent {
  padding: 0.5rem;
  width: 100%;
  margin: auto;
  position: fixed;
  bottom: 0;
  z-index: 1100;
  background: #000;
}
.cookie-consent__text {
  width: 50%;
  float: left;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 2rem;
}
.cookie-consent__action {
  width: 45%;
  float: left;
}
.cookie-consent__action_accept {
  float: right;
  padding: 0.5rem 2rem 0.5rem 2rem;
  margin-right: 2rem;
  background-color: #087dc2;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.recaptcha__check {
  float: right;
  padding-right: 10rem;
}
.recaptcha__validation_error {
  color: #fff;
  margin-left: 12.5rem;
  float: left;
}

.remote-filter-pagination {
  display: flex;
  padding-left: 0;
  border-radius: 4px;
  width: 45%;
  float: right;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  justify-content: flex-end;
  margin-right: 0.5rem;
}
.remote-filter-pagination__pagination {
  float: right;
  display: contents;
}
.remote-filter-pagination__pagination_first, .remote-filter-pagination__pagination_prev, .remote-filter-pagination__pagination_last, .remote-filter-pagination__pagination_next, .remote-filter-pagination__pagination_page-count {
  float: left;
  padding: 4px 10px;
  line-height: 1rem;
  color: #087dc2;
  border: 1px solid #bfbfbf;
  padding-top: 6px;
}
.remote-filter-pagination__pagination_first:hover, .remote-filter-pagination__pagination_prev:hover, .remote-filter-pagination__pagination_last:hover, .remote-filter-pagination__pagination_next:hover, .remote-filter-pagination__pagination_page-count:hover {
  cursor: pointer;
}
.remote-filter-pagination__pagination_page-count {
  padding: 1px 10px;
  width: 25%;
  text-align: center;
  padding-top: 4px;
}
.remote-filter-pagination__pagination_page-count .select-page {
  text-align: right;
  width: 50%;
}
.remote-filter-pagination__filter {
  float: left;
  margin-left: 1rem;
}
.remote-filter-pagination__filter_button {
  background-color: #087dc2;
  color: #fff;
  font-size: 10px;
  border: none;
  line-height: inherit;
  padding: 0.5rem;
  text-align: center;
  text-transform: uppercase;
}

.traveler-remote-filter {
  border: 5px solid #c7e5f6;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.traveler-remote-filter__form {
  display: flex;
  height: 50px;
  align-items: flex-start;
  align-content: flex-end;
  flex-direction: row;
}
.traveler-remote-filter__form_first-name, .traveler-remote-filter__form_last-name, .traveler-remote-filter__form_email, .traveler-remote-filter__form_phone-no {
  width: 20%;
}
.traveler-remote-filter__form_first-name .text-label, .traveler-remote-filter__form_last-name .text-label, .traveler-remote-filter__form_email .text-label, .traveler-remote-filter__form_phone-no .text-label {
  color: #087dc2 !important;
  font-size: 0.875rem;
  margin-bottom: 0;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}
.traveler-remote-filter__form_first-name .text-input, .traveler-remote-filter__form_last-name .text-input, .traveler-remote-filter__form_email .text-input, .traveler-remote-filter__form_phone-no .text-input {
  width: 80%;
}
.traveler-remote-filter__form_first-name .text-input input[type=text], .traveler-remote-filter__form_first-name .text-input input[type=number], .traveler-remote-filter__form_first-name .text-input select, .traveler-remote-filter__form_first-name .text-input textarea, .traveler-remote-filter__form_last-name .text-input input[type=text], .traveler-remote-filter__form_last-name .text-input input[type=number], .traveler-remote-filter__form_last-name .text-input select, .traveler-remote-filter__form_last-name .text-input textarea, .traveler-remote-filter__form_email .text-input input[type=text], .traveler-remote-filter__form_email .text-input input[type=number], .traveler-remote-filter__form_email .text-input select, .traveler-remote-filter__form_email .text-input textarea, .traveler-remote-filter__form_phone-no .text-input input[type=text], .traveler-remote-filter__form_phone-no .text-input input[type=number], .traveler-remote-filter__form_phone-no .text-input select, .traveler-remote-filter__form_phone-no .text-input textarea {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
}
.traveler-remote-filter__form_first-name .text-input input[type=text], .traveler-remote-filter__form_first-name .text-input input[type=number], .traveler-remote-filter__form_first-name .text-input select, .traveler-remote-filter__form_last-name .text-input input[type=text], .traveler-remote-filter__form_last-name .text-input input[type=number], .traveler-remote-filter__form_last-name .text-input select, .traveler-remote-filter__form_email .text-input input[type=text], .traveler-remote-filter__form_email .text-input input[type=number], .traveler-remote-filter__form_email .text-input select, .traveler-remote-filter__form_phone-no .text-input input[type=text], .traveler-remote-filter__form_phone-no .text-input input[type=number], .traveler-remote-filter__form_phone-no .text-input select {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.traveler-remote-filter__form_first-name .text-input input[type=number], .traveler-remote-filter__form_last-name .text-input input[type=number], .traveler-remote-filter__form_email .text-input input[type=number], .traveler-remote-filter__form_phone-no .text-input input[type=number] {
  font-family: "Open Sans";
}
.traveler-remote-filter__form_first-name .text-input input[type=text], .traveler-remote-filter__form_last-name .text-input input[type=text], .traveler-remote-filter__form_email .text-input input[type=text], .traveler-remote-filter__form_phone-no .text-input input[type=text] {
  height: 24px;
  padding: 2px 1px;
  border-radius: 3px;
}
.traveler-remote-filter__form_submit-button, .traveler-remote-filter__form_reset-button {
  margin-top: 16px;
}
.traveler-remote-filter__form_submit-button button, .traveler-remote-filter__form_reset-button button {
  background-color: #ec2227;
  border: 1px solid #959B9E;
  color: #fff;
  margin-right: 0.125rem;
  padding: 0.2rem 0.1rem;
  width: 8rem;
  border: none;
  text-transform: uppercase;
}
.traveler-remote-filter__form_reset-button button {
  background-color: #087dc2;
}