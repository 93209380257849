//
// Modals
// --------------------------------------------------
// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit
// Kill the scroll on the body
.modal-open {
  overflow: hidden;
  padding-right: 15px !important;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    @include translate(0, -25%);
    @include transition-transform(0.3s ease-out);
  }
  &.in .modal-dialog {
    @include translate(0, 0);
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

// Actual modal
.modal-content {
  padding: 12px 10px 2px 20px;
  position: relative;
  background-color: $modal-content-bg;
  border: 1px solid $modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid $modal-content-border-color;
  @include box-shadow(0 3px 9px rgba(0,0,0,.5));
  background-clip: padding-box;

  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-background;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade {
    @include opacity(0);
  }
  &.in {
    @include opacity($modal-backdrop-opacity);
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: $modal-title-padding;
  border-bottom: 1px solid $modal-header-border-color;
  min-height: ($modal-title-padding + $modal-title-line-height);
  color: $brand-color-2;
  font-size: 0.875rem;
}

// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  margin: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  padding: $modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid $modal-footer-border-color;
  @include clearfix; // clear it in case folks use .pull-* classes on buttons

// Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }

  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }

  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
/*@media (min-width: $sm-screen-size) {

  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: $modal-md-size;
    margin: 30px auto;
  }
  .modal-content {
    @include box-shadow(0 5px 15px rgba(0,0,0,.5));
  }

  // Modal sizes
  .modal-sm {
    width: $modal-sm-size;
  }
}
@media (min-width: $md-screen-size) {
  .modal-lg {
    width: $modal-lg-size;
  }
}*/

.modal-wrapper{
.modal-dialog {
	margin: 30px auto;
	@include make-responsive-width('max-width',$modal-sm-size,$modal-md-size,$modal-lg-size);
	}
}
.modal-content {
  padding: 0.9375rem;
}
.modal {
  &__content {
    overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-top: $base-padding/2;
    padding-bottom: $base-padding/2;
    &_loader{
    
    padding: 15px;
    margin-left: 45%;
    }
  }
  &__header {
    @include clearfix;
    color: $brand-color-2;
    font-size: $font-size-h4;
    width: 100%;
    &_heading {
      width: 95%;
      float: left;
    }
    &_close {
      width: 5%;
      float: right;
      text-align: right;
      cursor: pointer;
    }
    &_info {
      margin-top: 7px;
      font-size: $font-size-h3;
      background-color: lighten($brand-color-3,8);
      height: 35px;
      padding: 5px 5px 5px 7px;
    }
  }
}
.heading__main {
  @include clearfix;
}
.header__info {
  &_part {
    color: $brand-color-2;
    float: left;
  }
  &_part1 {
    color: lighten($brand-color-1,20);
    margin-left: 5px;
    font-family: $font-family-number;
  }
  &_part2 {
    color: $brand-color-2;
    float: right;
    font-size: 0.875rem;
    margin: 2px;
  }
  &_subpart1 {
    padding-right: 10px;
  }
  &_subpart2 {
    padding: 3px 20px;
    background-color: white;
  }
}

 .highlights {
    &__sidebar {
      float: left;
      width: 25%;
      height: 100%;
      background-color: $body-bg;
    }
    &__hotel-details {
      float: right;
      width: 75%;
      height: 100%;
      margin-top: 10px;
    }
  }
 .sidebar {
    &__back-button {
      margin: 20px;
      background-color: white;
      border: 5px solid $brand-color-2;
      padding: 25px;
      button {
        cursor: pointer;
        padding-top: $base-padding/6;
        padding-bottom: $base-padding/6;
        border: none;
        line-height: inherit;
        border-radius: 0;
        background-image: none;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        background-color: $brand-color-1;
        color: $light;
        display: block;
        &:hover {
          background-color: darken($brand-color-1, 20);
        }
      }
    }
    &__tabs {
      width: 100%;
      height: 80%;
      padding-bottom: 10px;
      >li {
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        position: relative;
        >a {
          color: $brand-color-2;
          padding-top: 15px;
          padding-bottom: 15px;
          display: block;
          text-decoration: none;
          cursor: pointer;
        }
        &:hover {
          background-color: $brand-color-2;
          a {
            color: $light;
          }
        }
        &:after {
          content: "";
          width: 80%;
          margin: 0 auto;
          height: 1px;
          background-color: white;
          position: absolute;
          top: 100%;
        }
      }
      .active {
        background-color: $light;
        a {
          color: $brand-color-1;
        }
      }
    }
}

.modal__header {
  color: $brand-color-2;
  font-size: $font-size-h4;
  &_heading {
    padding-left: 7px;
    float:left
  }
  &_close{
    float:right;
    cursor:pointer;
  }
  &_info {
    //color:$brand-color-2;
    margin-top: 7px;
    font-size: $font-size-h3;
    background-color: lighten($brand-color-3,8);
    height: 35px;
    padding: 5px 5px 5px 7px;
  }
  .alert__message--error{
    margin-top:10px;
  }
}
.heading__main{
  @include clearfix;
}
.header__info {
  color: $brand-color-2;
  }
.header__info_part1 {
  color: lighten($brand-color-1,20);
  float: left;
  font-size: 0.875rem;
  margin: 2px;
}
.header__info_part2 {
  color: $brand-color-2;
  float: right;
  font-size: 0.875rem;
  margin: 2px;
  margin-top : 0rem;
  font-family: $font-family-number;
}
.header__info_part3 {
  color: lighten($brand-color-1,20);
  font-size: 0.875rem;
  margin: 2px;
}
.adhoc-notification-header{
			@include clearfix;
			margin-top: 0rem;
			padding: 0.25rem;
		    	color: $brand-color-20;
		    	background-color: $brand-color-21;
		    	box-shadow: $row-shadow-color;
		    	.adhoc-message{
		    		font-size: 0.9rem;
		    		padding-left: 0.25rem;
		    	}
}

@media print{
  .modal-content {
    box-shadow : none;
    border : none;
  }
}